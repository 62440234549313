import React from "react";

const PrintPage = React.lazy(() => import("./views/Songs/PrintPage"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const IndexPage = React.lazy(() => import("./views/IndexPage"));
const VerifyEmailPage = React.lazy(() =>
  import("./views/Verifcation/VerifyEmailPage")
);
const SongPage = React.lazy(() => import("./views/Songs/SongPage"));
const IndiPage = React.lazy(() => import("./views/Songs/Indi"));
const PageNotFound = React.lazy(() =>
  import("./components/Fallbacks/PageNotFound")
);
const LoginPage = React.lazy(() => import("./views/Auth/LoginPage"));
const RegisterPage = React.lazy(() => import("./views/Auth/RegisterPage"));
const IndiAnnouncementPage = React.lazy(() =>
  import("./views/IndiAnnouncementPage")
);
const ResetPasswordPage = React.lazy(() =>
  import("./views/Verifcation/ResetPasswordPage")
);
const ResetPasswordConfirmPage = React.lazy(() =>
  import("./views/Verifcation/ResetPasswordConfirmPage")
);
const PopularSongsPage = React.lazy(() =>
  import("./views/PopularSongs/PopularSongsPage")
);
const AboutPage = React.lazy(() => import("./views/AboutPage"));
const AllAnnouncements = React.lazy(() => import("./views/AllAnnouncements"));
const ContactPage = React.lazy(() => import("./views/ContactPage"));
const ProfilePage = React.lazy(() => import("./views/Auth/ProfilePage"));
const LegendPage = React.lazy(() => import("./views/LegendPage"));
const AddTestimonialPage = React.lazy(() =>
  import("./views/Testimonial/AddTestimonialPage")
);
const AllTestimonialsPage = React.lazy(() =>
  import("./views/Testimonial/ViewTestimonialsPage")
);

export const paths = [
  {
    path: "/",
    component: <IndexPage />,
  },
  {
    path: "/songs/:lang",
    component: <SongPage />,
  },
  {
    path: "/contact",
    component: <ContactPage />,
  },
  {
    path: "/print-song/:songId/:version/:songName",
    component: <PrintPage />,
  },
  {
    path: "/about",
    component: <AboutPage />,
  },
  {
    path: "/legend",
    component: <LegendPage />,
  },
  {
    path: "/recitals",
    component: <h1>Coming soon</h1>,
  },
  {
    path: "/:lang/:songId/:songName/:version",
    component: <IndiPage />,
  },
  {
    path: "/announcements",
    component: <AllAnnouncements />,
  },
  {
    path: "/modal-demo",
    component: <h1>Coming soon</h1>,
  },
  {
    path: "/auth/signin",
    component: <LoginPage />,
  },
  {
    path: "/auth/signup",
    component: <RegisterPage />,
  },
  {
    path: "/profile",
    component: <ProfilePage />,
  },
  {
    path: "/announcements/view/:annId",
    component: <IndiAnnouncementPage />,
  },
  {
    path: "/reset-password",
    component: <ResetPasswordPage />,
  },
  {
    path: "/popular-songs",
    component: <PopularSongsPage />,
  },
  {
    path: "/reset-password/:verifyToken",
    component: <ResetPasswordConfirmPage />,
  },
  {
    path: "/verify-email",
    component: <VerifyEmailPage />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/testimonial/new",
    component: <AddTestimonialPage />,
  },
  {
    path: "/testimonial/see-all",
    component: <AllTestimonialsPage />,
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
];
