import React from "react";
// import ReactDOM from "react-dom";

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import ColumnCtxProvider from "./store/ColumnsContext";

import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./store/AuthContext";

// ReactDOM.render(
//   <BrowserRouter>
//     <ColumnCtxProvider>
//       <AuthContextProvider>
//           <App />
//       </AuthContextProvider>
//     </ColumnCtxProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ColumnCtxProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ColumnCtxProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
