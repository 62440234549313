import React from "react";

const TranscribePage = React.lazy(() =>
  import("./views/Admin/Songs/TranscribePage")
);
const SongsPage = React.lazy(() => import("./views/Admin/Songs/SongsPage"));
const AdminAnnouncementsPage = React.lazy(() =>
  import("./views/Admin/Announcements/AdminAnnouncementsPage")
);
const EditPage = React.lazy(() => import("./views/Admin/Songs/EditPage"));
const ChangeView = React.lazy(() => import("./views/Admin/Songs/ChangeView"));
const DeleteSong = React.lazy(() => import("./views/Admin/Songs/DeleteSong"));
const PostAnnouncement = React.lazy(() =>
  import("./views/Admin/Announcements/PostAnnouncement")
);
const EditAnnouncementsPage = React.lazy(() =>
  import("./views/Admin/Announcements/EditAnnouncementsPage")
);
const ChangeViewAnn = React.lazy(() =>
  import("./views/Admin/Announcements/ChangeViewAnn")
);
const DeleteAnn = React.lazy(() =>
  import("./views/Admin/Announcements/DeleteAnn")
);
const ViewUsersPage = React.lazy(() =>
  import("./views/Admin/Users/ViewUsersPage")
);
const ViewTestimonialsPage = React.lazy(() =>
  import("./views/Admin/Testimonials/ViewTestimonialsPage")
);

export const adminPaths = [
  {
    path: "/admin/users",
    component: <ViewUsersPage />,
  },
  {
    path: "/admin/transcribe",
    component: <TranscribePage />,
  },
  {
    path: "/admin/songs",
    component: <SongsPage />,
  },
  {
    path: "/admin/announcements",
    component: <AdminAnnouncementsPage />,
  },
  {
    path: "/admin/new-announcement",
    component: <PostAnnouncement />,
  },
  {
    path: "/admin/edit/:songId/:version",
    component: <EditPage />,
  },
  {
    path: "/admin/delete/:songId",
    component: <DeleteSong />,
  },
  {
    path: "/admin/change-view/:songId",
    component: <ChangeView />,
  },
  {
    path: "/admin/announcements/edit/:id",
    component: <EditAnnouncementsPage />,
  },
  {
    path: "/admin/announcements/change-view/:annId",
    component: <ChangeViewAnn />,
  },
  {
    path: "/admin/announcements/delete/:annId",
    component: <DeleteAnn />,
  },
  {
    path: "/admin/testimonials/view",
    component: <ViewTestimonialsPage />,
  },
];
