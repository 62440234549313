export const fetchReal = (url, options = {}) => {
  options.cache = "no-store";
  let apiURL;
  if (process.env.NODE_ENV === "production") {
    apiURL = `https://backend-api.keylessonline.com/api${url}`; // prod
  } else {
    apiURL = `http://localhost:8001/api${url}`; // dev
  }

  return fetch(apiURL, options);
};
