import { createContext, useMemo } from "react";
import { Link } from "react-router-dom";

export const ColumnsContext = createContext();

const ColumnCtxProvider = (props) => {
  const columns = useMemo(
    () => [
      {
        Header: "Song Name",
        accessor: "songName",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <p className="tw-font-medium tw-text-gray-900 tw-whitespace-nowrap">
              {data.songName}
            </p>
          );
        },
      },
      {
        Header: "Movie Name",
        accessor: "movieName",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <p className="tw-font-medium tw-text-gray-900 tw-whitespace-nowrap">
              {data.movieName}
            </p>
          );
        },
      },
      {
        Header: "Carnatic Version",
        accessor: "carnatic",
        Cell: ({ row }) => {
          const data = row.original;
          let final = <></>;
          if (data.carnaticText === "Not Available") {
            final = (
              <p className="tw-cursor-not-allowed tw-text-gray-400">{data.carnaticText}</p>
            );
          } else {
            final = (
              <Link
                to={data.carnatic}
                className="tw-transition-all tw-text-sky-700 tw-ease-in-out tw-duration-300 tw-p-2 tw-rounded-lg tw-underline tw-decoration-sky-700 hover:tw-bg-gray-100 hover:tw-text-sky-800 hover:-tw-translate-y-1 hover:tw-underline hover:tw-decoration-sky-800"
              >
                {data.carnaticText}
              </Link>
            );
          }
          return final;
        },
      },
      {
        Header: "Western Version",
        accessor: "western",
        Cell: ({ row }) => {
          const data = row.original;
          let final = <></>;
          if (data.westernText === "Not Available") {
            final = <p className="tw-cursor-not-allowed tw-text-gray-400">{data.westernText}</p>;
          } else {
            final = (
              <Link
                to={data.western}
                className="tw-transition-all tw-ease-in-out tw-text-sky-700 tw-duration-300 tw-p-2 tw-rounded-lg tw-underline tw-decoration-sky-700 hover:tw-bg-gray-100 hover:tw-text-sky-800 hover:tw-decoration-sky-800"
              >
                {data.westernText}
              </Link>
            );
          }
          return final;
        },
      },
      {
        Header: "Band Version",
        accessor: "band",
        Cell: ({ row }) => {
          const data = row.original;
          let final = <></>;
          if (data.bandText === "Not Available") {
            final = <p className="tw-cursor-not-allowed tw-text-gray-400">{data.bandText}</p>;
          } else {
            final = (
              <Link
                to={data.band}
                className="tw-transition-all tw-ease-in-out tw-text-sky-700 tw-duration-300 tw-p-2 tw-rounded-lg tw-underline tw-decoration-sky-700 hover:tw-bg-gray-100 hover:tw-text-sky-800 hover:tw-decoration-sky-800"
              >
                {data.bandText}
              </Link>
            );
          }
          return final;
        },
      },
    ],
    []
  );

  const values = {
    columns,
  };

  return (
    <ColumnsContext.Provider value={values}>
      {props.children}
    </ColumnsContext.Provider>
  );
};

export default ColumnCtxProvider;
