import React from "react";
import { HeartIcon, MusicalNoteIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="tw-p-4 sm:tw-p-6 tw-rounded-t-md tw-mt-6 tw-mb-0 sm:tw-border-t-2 sm:tw-border-[#7685b7]">
      <div className="md:tw-flex md:tw-justify-between md:tw-mb-0">
        <div className="tw-flex tw-justify-start lg:tw-w-0 lg:tw-flex-1">
          <Link to="/">
            <span className="tw-sr-only">Keyless Online</span>
            <div className="tw-flex tw-flex-row tw-items-center">
              <MusicalNoteIcon className="tw-h-8 tw-w-auto sm:tw-h-10 tw-text-rose-700" />
              <span className="tw-text-2xl tw-font-bold tw-ml-2 tw-bg-gradient-to-br tw-bg-clip-text tw-text-transparent tw-from-rose-500 tw-via-red-700 tw-to-pink-900">
                Keyless Online
              </span>
            </div>
          </Link>
        </div>
        <div className="tw-grid tw-w-full tw-grid-cols-3 tw-gap-x-2 tw-gap-3 sm:tw-gap-6 sm:tw-grid-cols-3 tw-mt-5 sm:tw-mt-0 sm:tw-w-1/2">
          <div>
            <h2 className="tw-mb-6 tw-text-sm tw-font-semibold tw-text-gray-900 tw-uppercase">
              Company
            </h2>
            <ul className="tw-text-gray-600">
              <li className="tw-mb-4">
                <Link
                  to="/contact"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                >
                  Contact Us
                </Link>
              </li>
              <li className="tw-mb-4">
                <Link
                  to="/about"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="tw-mb-6 tw-text-sm tw-font-semibold tw-text-gray-900 tw-uppercase">
              Help Us Out
            </h2>
            <ul className="tw-text-gray-600">
              <li className="tw-mb-4">
                <a
                  href="https://www.paypal.com/paypalme/keylessonline"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                  target="_blank"
                  rel="noreferrer"
                >
                  Donate
                </a>
              </li>
            </ul>
            <ul className="tw-text-gray-600">
              <li className="tw-mb-4">
                <Link
                  to="/testimonial/see-all"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                >
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="tw-mb-6 tw-text-sm tw-font-semibold tw-text-gray-900 tw-uppercase">
              Legal
            </h2>
            <ul className="tw-text-gray-600">
              <li className="tw-mb-4 tw-mt-6">
                <a
                  href="https://creativecommons.org/licenses/by-nc-sa/2.5/"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                  target="_blank"
                  rel="noreferrer"
                >
                  Copyright Policy
                </a>
              </li>
              <li className="tw-mb-4 tw-mt-6">
                <Link
                  to="/privacy-policy"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                >
                  Privacy Policy
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/terms-of-use"
                  className="tw-transition-all tw-duration-300 hover:tw-underline hover:tw-text-rose-900"
                >
                  Terms of Use
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <hr className="tw-my-6 tw-border-gray-200 sm:tw-mx-auto lg:tw-my-8" />
      <div className="sm:tw-flex sm:tw-items-center sm:tw-justify-between tw-text-center">
        <span className="tw-text-sm tw-text-gray-500 sm:tw-text-center">
          ©1999-{new Date().getFullYear()}{" "}
          <Link
            to="/about"
            className="hover:tw-underline tw-transition-all tw-duration-300 tw-text-rose-700 hover:tw-text-rose-900"
          >
            KeylessOnline™
          </Link>
          . All Rights Reserved.
        </span>
        <div className="tw-flex tw-mt-4 tw-space-x-6 tw-justify-center sm:tw-mt-0">
          <a
            href="https://www.facebook.com/keylessonline"
            target="_blank"
            rel="noreferrer"
            className="tw-transition-all tw-flex tw-items-center tw-duration-300 tw-ease-in-out tw-text-gray-500 hover:tw-text-blue-500"
          >
            <svg
              className="tw-w-5 tw-h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sm:tw-hidden tw-ml-1">Facebook</span>
          </a>
          <a
            href="https://twitter.com/keylessonline"
            target="_blank"
            rel="noreferrer"
            className="tw-transition-all tw-flex tw-items-center tw-ease-in-out tw-duration-300 tw-text-gray-500 hover:tw-text-blue-700"
          >
            <svg
              className="tw-w-5 tw-h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
            </svg>
            <span className="sm:tw-hidden tw-ml-1">Twitter</span>
          </a>
        </div>
        <span className="tw-hidden sm:tw-flex tw-flex-row tw-text-gray-500 tw-items-center">
          Made with&nbsp;
          <HeartIcon className="tw-h-5 tw-w-5 tw-text-gray-500" />
          &nbsp;and React by&nbsp;
          <a
            href="https://www.linkedin.com/in/rohanramakrish"
            target="_blank"
            rel="noreferrer"
            className="tw-transition-all tw-duration-300 tw-text-rose-700 tw-underline hover:tw-text-rose-900 hover:tw-decoration-double"
          >
            Rohan Ramakrishnan
          </a>
          🙂
        </span>
        <span className="tw-flex sm:tw-hidden tw-items-center tw-mt-3 tw-justify-center">
          Made with&nbsp;
          <HeartIcon className="tw-h-3 tw-w-3 tw-text-gray-500" />
          &nbsp;and React by&nbsp;
          <a
            href="https://github.com/14r14"
            target="_blank"
            rel="noreferrer"
            className="tw-transition-all tw-duration-300 tw-text-rose-700 tw-underline hover:tw-text-rose-900 hover:tw-decoration-double"
          >
            Rohan Ramakrishnan
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
