import React, { Fragment, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import AuthContext from "./store/AuthContext";
// import RecitalModal from "./components/Modals/RecitalVideo";

import Loading from "./components/Fallbacks/Loading";
import Navbar from "./components/Navbar/Navbar";

import { adminPaths } from "./adminPaths";
import { paths } from "./paths";
import HorizontalAd from "./components/Ads/HorizontalAd";

function App() {
  const authCtx = useContext(AuthContext);

  window.recaptchaOptions = {
    enterprise: true,
  }

  return (
    <div>
      <Suspense fallback={<Loading text={"Getting stuff ready for you..."} />}>
        <Navbar />
        {/* <HorizontalAd /> */}
        <Routes>
          {paths.map((path, i) => {
            return <Route key={i} path={path.path} element={path.component} />;
          })}
          {authCtx.isLoggedIn && authCtx.accountType === "true" && (
            <Fragment>
              {adminPaths.map((path, i) => {
                return (
                  <Route key={i} path={path.path} element={path.component} />
                );
              })}
            </Fragment>
          )}
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
