import {
  StarIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import CloseButton from "react-bootstrap/CloseButton";
import { Link } from "react-router-dom";
import { fetchReal } from "../utils/fetch";

export default function Sidebar() {
  const [show, setShow] = useState(false);
  const [songs, setSongs] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let lng;
  if (songs) {
    if (songs.length > 1) {
      lng = true;
    } else {
      lng = false;
    }
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      handleClose();
    }

    const fetchFavorites = async () => {
      setLoading(true);
      fetchReal("/songs/get-saved-songs", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setSongs(data.songs);
          } else {
            if (data.code === "nosongsfound" || data.code === "songnotfound") {
              setSongs([]);
            }
          }
        });
    };

    fetchFavorites();
  }, [show]);

  return (
    <>
      <Button
        onClick={handleShow}
        className="me-2 tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-left tw-text-gray-700 tw-w-full tw-border-0 tw-outline-none hover:tw-text-sky-700 hover:tw-underline hover:tw-bg-gray-100"
      >
        <div className="tw-flex tw-flex-row">
          <StarIcon className="tw-h-5 tw-w-5 tw-mr-1" />
          Favorites
        </div>
      </Button>
      <Offcanvas show={show} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="tw-flex tw-flex-row tw-items-center">
              <StarIcon className="tw-h-5 tw-w-5 tw-mr-1" />
              Favorites
              <CloseButton onClick={handleClose}>
                <XMarkIcon className="tw-rounded-full tw-border tw-h-5 tw-w-5 tw-border-gray-700" />
              </CloseButton>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div class="tw-w-full tw-m-0 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200">
            {lng ? (
              <>
                {songs.map((song) => (
                  <div
                    key={song.id}
                    aria-current="true"
                    className="tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
                  >
                    <Link
                      to={`/${song.songLang.toLowerCase()}/${
                        song.id
                      }/${song.songName
                        .split(" ")
                        .join("-")
                        .toLowerCase()}/carnatic`}
                      className="tw-group"
                    >
                      <span className="tw-text-sky-700 tw-underline tw-decoration-sky-700">
                        {song.songLang}
                      </span>
                      &nbsp;
                      <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-sky-700">
                        {song.songName}
                      </span>
                    </Link>
                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-text-right hover:tw-text-red-600">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          fetchReal(
                            "/songs/delete-saved-song?songId=" + song.id,
                            {
                              method: "DELETE",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem(
                                  "token"
                                )}`,
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((data) => {
                              if (data.success) {
                                alert("Song successfully deleted.");
                              } else {
                                alert("The song doesn't exist.");
                              }
                            });
                        }}
                      >
                        <TrashIcon className="tw-h-5 tw-w-5 tw-mr-1" />
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {songs && songs.length === 1 ? (
                  <div
                    key={songs[0].id}
                    aria-current="true"
                    className="tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
                  >
                    <Link
                      to={`/${songs[0].songLang.toLowerCase()}/${
                        songs[0].id
                      }/${songs[0].songName
                        .split(" ")
                        .join("-")
                        .toLowerCase()}/carnatic`}
                      className="tw-group"
                    >
                      <span className="tw-text-sky-700 tw-underline tw-decoration-sky-700">
                        {songs[0].songLang}
                      </span>
                      &nbsp;
                      <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-sky-700">
                        {songs[0].songName}
                      </span>
                    </Link>
                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-text-right hover:tw-text-red-600">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          fetchReal(
                            "/songs/delete-saved-song?songId=" + songs[0].id,
                            {
                              method: "DELETE",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem(
                                  "token"
                                )}`,
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((data) => {
                              if (data.success) {
                                alert("Song successfully deleted.");
                              } else {
                                alert("The song doesn't exist.");
                              }
                            });
                        }}
                      >
                        <TrashIcon className="tw-h-5 tw-w-5 tw-mr-1" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {!loading && <p>No songs favorited yet. Favorite one.</p>}
                  </>
                )}
              </>
            )}
            {loading && <p>Loading Songs...</p>}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
