import { Fragment, useState, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3BottomRightIcon,
  MusicalNoteIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useMatch } from "react-router-dom";
import AuthContext from "../../store/AuthContext";
import Dropdown from "./Dropdown";

const songCategories = [
  {
    name: "Tamil",
    href: "/songs/tamil",
  },
  {
    name: "Hindi",
    href: "/songs/hindi",
  },
  {
    name: "Telugu",
    href: "/songs/telugu",
  },
  {
    name: "Kannada",
    href: "/songs/kannada",
  },
  {
    name: "Malayalam",
    href: "/songs/malayalam",
  },
  {
    name: "Kids",
    href: "/songs/kids",
  },
  {
    name: "Carnatic",
    href: "/songs/carnatic",
  },
  {
    name: "Devotional",
    href: "/songs/devotional",
  },
  {
    name: "English",
    href: "/songs/english",
  },
  {
    name: "Punjabi",
    href: "/songs/punjabi",
  },
  {
    name: "Marathi",
    href: "/songs/marathi",
  },
  {
    name: "Miscellaneous",
    href: "/songs/misc",
  },
  // {
  //   name: "Recitals",
  //   href: "/songs/recitals",
  // },
];

const additionalLinks = [
  {
    name: "Legend",
    href: "/legend",
  },
  {
    name: "About",
    href: "/about",
  },
  {
    name: "Testimonials",
    href: "/testimonial/see-all",
  },
];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function Navbar() {
  const isAuthPage = useMatch("/auth/*");

  const showNavbar = !isAuthPage;

  const authCtx = useContext(AuthContext);
  const [mobileMenu, setMobileMenu] = useState(false);

  const openMobileMenu = () => {
    setMobileMenu(true);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  const notLoggedIn = (
    <>
      <div className="tw-hidden md:tw-flex tw-items-center tw-justify-end md:tw-flex-1 lg:tw-w-0">
        <Link
          to="/auth/signin"
          className="tw-transition-all tw-ease-in-out tw-duration-300 tw-ml-4 hover:tw-text-white tw-whitespace-nowrap tw-inline-flex tw-items-center tw-text-black tw-justify-center tw-px-4 tw-py-2 tw-rounded-md tw-border tw-border-sky-700 tw-shadow-sm tw-text-base hover:-tw-text-white tw-font-medium hover:tw-bg-sky-700 hover:-tw-translate-y-1 hover:tw-shadow-lg"
          state={{ from: window.location.pathname }}
        >
          Sign In
        </Link>
        <Link
          to="/auth/signup"
          className="tw-transition-all tw-ease-in-out tw-duration-300 tw-ml-4 hover:tw-text-white tw-whitespace-nowrap tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-white tw-bg-sky-700 hover:tw-bg-sky-800 hover:-tw-translate-y-1 hover:tw-shadow-lg"
          state={{ from: window.location.pathname }}
        >
          Sign Up
        </Link>
      </div>
    </>
  );

  const loggedIn = (
    <>
      <div className="tw-hidden md:tw-flex tw-items-center tw-justify-end md:tw-flex-1 lg:tw-w-0">
        <Dropdown username={authCtx.username} />
      </div>
    </>
  );

  return (
    <Popover
      className={`${
        showNavbar ? "tw-relative" : "tw-hidden"
      } tw-w-full tw-bg-gradient-to-r tw-from-white tw-via-pink-100 tw-to-white tw-backdrop-blur-lg tw-z-40`}
    >
      <div className="tw-max-w-8xl tw-mx-auto tw-px-4 sm:tw-px-6">
        <div className="tw-flex tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-py-6 md:tw-justify-start md:tw-space-x-10">
          <div className="tw-flex tw-justify-start tw-items-center lg:tw-w-0 lg:tw-flex-1">
            <Link to="/">
              <span className="tw-sr-only">Keyless Online</span>
              <div className="tw-flex tw-flex-row tw-items-center">
                <MusicalNoteIcon className="tw-h-8 tw-w-auto sm:tw-h-10 tw-text-rose-700" />
                <span className="tw-text-2xl tw-font-bold tw-ml-2 tw-bg-gradient-to-br tw-bg-clip-text tw-text-transparent tw-from-rose-500 tw-via-red-700 tw-to-pink-900">
                  Keyless Online
                </span>
              </div>
            </Link>
            {/* <Link
              to="/keyless-academy"
              className="tw-group tw-ml-1"
            >
              <div className="tw-flex tw-flex-row tw-items-center">
                <span className="tw-text-xs tw-mr-1 group-hover:tw-text-black">from </span>
                <p className="tw-text-sm tw-underline tw-text-sky-600 group-hover:tw-text-sky-700 group-hover:tw-decoration-double"> Keyless Academy</p>
              </div>
            </Link> */}
          </div>
          <div className="-tw-mr-2 -tw-my-2 md:tw-hidden">
            <Popover.Button
              as="div"
              className="tw-bg-white tw-rounded-md tw-p-2 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-500 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-rose-700"
            >
              <button onClick={openMobileMenu}>
                <span className="tw-sr-only">Open menu</span>
                <Bars3BottomRightIcon
                  className="tw-h-6 tw-w-6"
                  aria-hidden="true"
                />
              </button>
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="tw-hidden md:tw-flex tw-space-x-10 md:tw-justify-center"
          >
            <Popover className="tw-relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`tw-group tw-bg-transparent tw-text-slate-800 tw-rounded-md tw-inline-flex tw-items-center tw-text-base tw-font-medium hover:tw-text-gray-900 hover:tw-underline focus:tw-outline-none`}
                  >
                    <span>Songs</span>
                    <ChevronDownIcon
                      className={`tw-transition-all tw-duration-300 tw-ease-in-out tw-ml-2 tw-text-slate-800 tw-h-5 tw-w-5 group-hover:tw-text-gray-900 ${
                        open && "tw-rotate-180"
                      }`}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-200"
                    enterFrom="tw-opacity-0 tw-translate-y-1"
                    enterTo="tw-opacity-100 tw-translate-y-0"
                    leave="tw-transition tw-ease-in tw-duration-150"
                    leaveFrom="tw-opacity-100 tw-translate-y-0"
                    leaveTo="tw-opacity-0 tw-translate-y-1"
                  >
                    <Popover.Panel className="tw-absolute tw-z-10 -tw-ml-4 tw-mt-3 tw-transform tw-px-2 tw-w-screen tw-max-w-md sm:tw-px-0 lg:tw-ml-0 lg:tw-left-1/2 lg:-tw-translate-x-1/2">
                      <div className="tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
                        <div className="tw-relative tw-grid tw-gap-6 tw-bg-white/90 tw-backdrop-blur-sm tw-px-5 tw-py-6 sm:tw-gap-8 sm:tw-p-8">
                          {songCategories.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="tw-group tw-transition-all tw-ease-in-out tw-duration-300 -tw-m-3 tw-p-2 tw-flex tw-items-start tw-rounded-lg hover:tw-bg-gray-100/70 hover:tw-border hover:tw-border-sky-700 hover:tw-backdrop-blur-sm hover:tw-text-pink-500 hover:-tw-translate-y-1 focus:tw-outline-none"
                            >
                              <div className="tw-ml-4">
                                <p className="tw-text-base tw-font-medium tw-text-gray-900 group-hover:tw-text-sky-700 group-hover:tw-border-b group-hover:tw-border-sky-700">
                                  {item.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Popover className="tw-relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`tw-group tw-bg-transparent tw-text-slate-800 tw-rounded-md tw-inline-flex tw-items-center tw-text-base tw-font-medium hover:tw-text-gray-900 hover:tw-underline focus:tw-outline-none`}
                  >
                    <span>Explore</span>
                    <ChevronDownIcon
                      className={`tw-transition-all tw-duration-300 tw-ease-in-out tw-ml-2 tw-text-slate-800 tw-h-5 tw-w-5 group-hover:tw-text-gray-900 ${
                        open && "tw-rotate-180"
                      }`}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-200"
                    enterFrom="tw-opacity-0 tw-translate-y-1"
                    enterTo="tw-opacity-100 tw-translate-y-0"
                    leave="tw-transition tw-ease-in tw-duration-150"
                    leaveFrom="tw-opacity-100 tw-translate-y-0"
                    leaveTo="tw-opacity-0 tw-translate-y-1"
                  >
                    <Popover.Panel className="tw-absolute tw-z-10 -tw-ml-4 tw-mt-3 tw-transform tw-px-2 tw-w-screen tw-max-w-md sm:tw-px-0 lg:tw-ml-0 lg:tw-left-1/2 lg:-tw-translate-x-1/2">
                      <div className="tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden">
                        <div className="tw-relative tw-grid tw-gap-6 tw-bg-white/90 tw-backdrop-blur-sm tw-px-5 tw-py-6 sm:tw-gap-8 sm:tw-p-8">
                          {additionalLinks.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="tw-group tw-transition-all tw-ease-in-out tw-duration-300 -tw-m-3 tw-p-2 tw-flex tw-items-start tw-rounded-lg hover:tw-bg-gray-100/70 hover:tw-border hover:tw-border-sky-700 hover:tw-backdrop-blur-sm hover:tw-text-pink-500 hover:-tw-translate-y-1 focus:tw-outline-none"
                            >
                              <div className="tw-ml-4">
                                <p className="tw-text-base tw-font-medium tw-text-gray-900 group-hover:tw-text-sky-700 group-hover:tw-border-b group-hover:tw-border-sky-700">
                                  {item.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link
              to="/popular-songs"
              className={`tw-group tw-bg-transparent tw-text-slate-800 tw-rounded-md tw-inline-flex tw-items-center tw-text-base tw-font-medium hover:tw-text-gray-900 hover:tw-underline focus:tw-outline-none`}
            >
              Popular Songs
            </Link>
          </Popover.Group>
          {authCtx.isLoggedIn ? loggedIn : notLoggedIn}
        </div>
      </div>
      {mobileMenu && (
        <Transition
          as={Fragment}
          enter="tw-duration-200 tw-ease-out"
          enterFrom="tw-opacity-0 tw-scale-95"
          enterTo="tw-opacity-100 tw-scale-100"
          leave="tw-duration-100 tw-ease-in"
          leaveFrom="tw-opacity-100 tw-scale-100"
          leaveTo="tw-opacity-0 tw-scale-95"
        >
          <Popover.Panel
            focus
            className="tw-absolute tw-top-0 tw-inset-x-0 tw-p-2 tw-transition tw-transform tw-origin-top-right md:tw-hidden"
          >
            <div className="tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-bg-gradient-to-br tw-from-white tw-via-rose-100 tw-to-pink-200 tw-divide-y-2 tw-divide-gray-50">
              <div className="tw-pt-5 tw-pb-6 tw-px-5">
                <div className="tw-flex tw-items-center tw-justify-between">
                  <Link to="/">
                    <span className="tw-sr-only">Keyless Online</span>
                    <div className="tw-flex tw-flex-row tw-items-center">
                      <MusicalNoteIcon className="tw-h-8 tw-w-auto sm:tw-h-10 tw-text-rose-700" />
                      <span className="tw-text-2xl tw-font-bold tw-ml-2 tw-bg-gradient-to-br tw-bg-clip-text tw-text-transparent tw-from-rose-500 tw-via-red-700 to-pink-900">
                        Keyless Online
                      </span>
                    </div>
                  </Link>
                  <div className="-tw-mr-2">
                    <Popover.Button className="tw-bg-white tw-rounded-md tw-p-2 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-500 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-rose-700">
                      <button onClick={closeMobileMenu}>
                        <span className="tw-sr-only">Close menu</span>
                        <XMarkIcon
                          className="tw-h-6 tw-w-6"
                          aria-hidden="true"
                        />
                      </button>
                    </Popover.Button>
                  </div>
                </div>
                <div className="tw-mt-6">
                  <nav className="tw-grid tw-gap-y-8">
                    {songCategories.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="tw-group -tw-m-3 tw-p-3 tw-flex tw-items-center tw-rounded-md hover:tw-text-sky-700"
                      >
                        <span className="tw-transition-all tw-ease-in-out tw-duration-100 tw-ml-3 tw-text-base tw-font-medium tw-text-gray-900 group-hover:tw-border-b-2 group-hover:tw-border-sky-700">
                          {item.name}
                        </span>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="tw-py-6 tw-px-5 tw-space-y-6">
                <div className="">
                  {!authCtx.isLoggedIn ? (
                    <>
                      <Link
                        to="/auth/signin"
                        className="tw-transition-all tw-ease-in-out tw-duration-300 tw-w-full tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-black hover:tw-text-white tw-border-sky-700 hover:tw-bg-sky-700"
                      >
                        Sign In
                      </Link>
                      <Link
                        to="/auth/signup"
                        className="tw-mt-3 tw-transition-all tw-ease-in-out tw-duration-300 tw-w-full tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-white hover:tw-text-white tw-bg-sky-700 hover:tw-bg-sky-800"
                      >
                        Sign Up
                      </Link>
                    </>
                  ) : (
                    <div className="tw-ml-3">
                      {authCtx.accountType && (
                        <>
                          <h3 className="tw-font-black tw-text-2xl">
                            Dashboard
                          </h3>
                          <p className="tw-text-xs tw-italic">
                            Some admin features not available on phones
                          </p>
                          <div>
                            <Link
                              to="/admin/users"
                              className="tw-group -tw-m-3 tw-p-3 tw-flex tw-items-center tw-rounded-md hover:tw-text-sky-700 tw-underline tw-decoration-sky-700 tw-text-sky-700"
                            >
                              Users
                            </Link>
                            <Link
                              to="/admin/announcements"
                              className="tw-group -tw-m-3 tw-p-3 tw-flex tw-items-center tw-rounded-md hover:tw-text-sky-700 tw-underline tw-decoration-sky-700 tw-text-sky-700"
                            >
                              Manage Announcements
                            </Link>
                          </div>
                        </>
                      )}
                      <div className="tw-border-b-[3px] tw-border-indigo-800 tw-mt-2" />
                      <h3 className="tw-font-black tw-text-2xl">Your Stuff</h3>
                      <Link
                        to="/favs"
                        className="tw-group -tw-m-3 tw-p-3 tw-flex tw-items-center tw-rounded-md hover:tw-text-sky-700 tw-underline tw-decoration-sky-700 tw-text-sky-700"
                      >
                        Favorites
                      </Link>{" "}
                      {/* TO DO */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      )}
    </Popover>
  );
}
