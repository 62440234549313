import {
  ChartBarIcon,
  DocumentIcon,
  PencilIcon,
  MegaphoneIcon,
  UserIcon,
  XMarkIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import CloseButton from "react-bootstrap/CloseButton";
import { Link } from "react-router-dom";
import { fetchReal } from "../../utils/fetch";
import { Transition } from "@headlessui/react";

export default function Sidebar() {
  const [show, setShow] = useState(false);

  const [pendingTestimonials, setPendingTestimonials] = useState(null);

  const [msg, setMsg] = useState(null);
  const [msgType, setMsgType] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchPendingTestimonials = async () => {
      try {
        const res = await fetchReal("/testimonial/admin/num-pending", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await res.json();
        if (!data.success) throw new Error(data.message);
        setPendingTestimonials(data.pending);
      } catch (error) {
        setMsg("Error fetching pending testimonials");
        setMsgType("error");
      }
    };
    
    fetchPendingTestimonials();
  }, []);

  return (
    <>
      <Button
        onClick={handleShow}
        className="me-2 tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-left tw-text-gray-700 tw-w-full tw-border-0 tw-outline-none hover:tw-text-sky-700 hover:tw-underline hover:tw-bg-gray-100"
      >
        <div className="tw-flex tw-flex-row">
          <ChartBarIcon className="tw-h-5 tw-w-5 tw-mr-1" />
          Dashboard
        </div>
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="tw-flex tw-flex-row tw-items-center">
              <ChartBarIcon className="tw-h-5 tw-w-5 tw-mr-1" />
              Dashboard
              <CloseButton onClick={handleClose}>
                <XMarkIcon className="tw-rounded-full tw-border tw-h-5 tw-w-5 tw-border-gray-700" />
              </CloseButton>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Transition
            as="div"
            show={msg != null && msgType != null}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          >
            <p
              className={`tw-text-sm tw-p-3 tw-my-2 tw-flex tw-items-center tw-justify-center tw-text-center ${
                msgType === "error" ? "tw-bg-red-100" : "tw-bg-green-100"
              } tw-border-2 ${
                msgType === "error"
                  ? "tw-border-red-700"
                  : "tw-border-green-700"
              } tw-rounded-lg ${
                msgType === "error" ? "tw-text-red-700" : "tw-border-green-700"
              } tw-text-semibold`}
            >
              {msg}
            </p>
          </Transition>
          <div className="tw-w-full tw-m-0 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200">
            <Link
              to="/admin/users"
              aria-current="true"
              className="tw-group tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
            >
              <div>
                <UserIcon className="tw-w-5 tw-h-5 tw-mr-2 group-hover:tw-text-rose-700" />
              </div>
              <div>
                <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-rose-700 group-hover:tw-underline group-hover:tw-decoration-rose-700">
                  Users
                </span>
              </div>
            </Link>
            <Link
              to="/admin/announcements"
              aria-current="true"
              className="tw-group tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
            >
              <div>
                <MegaphoneIcon className="tw-w-5 tw-h-5 tw-mr-2 group-hover:tw-text-rose-700" />
              </div>
              <div>
                <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-rose-700 group-hover:tw-underline group-hover:tw-decoration-rose-700">
                  Manage Announcements
                </span>
              </div>
            </Link>
            <Link
              to="/admin/songs"
              aria-current="true"
              className="tw-group tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
            >
              <div>
                <DocumentIcon className="tw-w-5 tw-h-5 tw-mr-2 group-hover:tw-text-rose-700" />
              </div>
              <div>
                <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-rose-700 group-hover:tw-underline group-hover:tw-decoration-rose-700">
                  Manage Songs
                </span>
              </div>
            </Link>
            <Link
              to="/admin/testimonials/view"
              aria-current="true"
              className="tw-group tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
            >
              <div>
                <ChatBubbleBottomCenterTextIcon className="tw-w-5 tw-h-5 tw-mr-2 group-hover:tw-text-rose-700" />
              </div>
              <div>
                <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-rose-700 group-hover:tw-underline group-hover:tw-decoration-rose-700">
                  Manage Testimonials
                  <span className="tw-ml-1 tw-inline-flex tw-items-center tw-rounded-md tw-bg-yellow-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-yellow-800 tw-ring-1 tw-ring-inset tw-ring-yellow-600/20">
                    {pendingTestimonials ? pendingTestimonials : "0"} Pending
                  </span>
                </span>
              </div>
            </Link>
            <Link
              to="/admin/transcribe"
              aria-current="true"
              className="tw-group tw-flex tw-flex-row tw-items-center tw-transition-all tw-ease-in-out tw-duration-300 hover:tw-bg-gray-200 tw-w-full tw-px-4 tw-py-2 tw-text-gray-700 tw-border-b tw-border-gray-200 tw-cursor-pointer"
            >
              <div>
                <PencilIcon className="tw-w-5 tw-h-5 tw-mr-2 group-hover:tw-text-rose-700" />
              </div>
              <div>
                <span className="tw-transition-all tw-ease-in-out tw-duration-300 group-hover:tw-text-rose-700 group-hover:tw-underline group-hover:tw-decoration-rose-700">
                  Transcribe Songs
                </span>
              </div>
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
