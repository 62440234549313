import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../store/AuthContext";
import FavoritesSidebar from "../User/FavoritesSidebar";
import Sidebar from "../Admin/Dashboard/Sidebar";
import { useEffect } from "react";
import { useState } from "react";

export default function Dropdown(props) {
  const [initials, setInitials] = useState(null);
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (!authCtx.isLoggedIn) {
      navigate("/auth/signin", { replace: true });
    }
  }, []);

  const admin = (
    <>
      {/* <div className="tw-py-1"> */}
      <Menu.Item>
        {({ active }) => (
          <>
            <Sidebar />
          </>
        )}
      </Menu.Item>
      {/* </div> */}
    </>
  );

  const logoutHandler = (e) => {
    e.preventDefault();
    authCtx.logout();
    navigate("/auth/signin", { replace: true });
  };

  const username = props.username;

  useEffect(() => {
    if (username) {
      const firstInitial = username.charAt(0);
      let secondInitial = "";
      if (username.includes(" ")) {
        secondInitial = username.charAt(username.indexOf(" ") + 1);
      }
      setInitials(firstInitial + secondInitial);
    }
  }, [username]);

  return (
    <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="tw-transition-all tw-duration-300 tw-inline-flex tw-justify-center tw-items-center tw-w-full tw-rounded-md tw-mr-1 tw-text-sm tw-font-medium tw-text-gray-100 focus:tw-outline-none tw-bg-pink-100/50 tw-border tw-border-rose-700 tw-p-2 tw-shadow-lg ui-open:tw-bg-pink-100 ui-open:tw-text-gray-900 ui-open:tw-shadow-xl ui-open:tw-border-rose-900">
              <div className="tw-flex tw-items-center">
                <div className="tw-mr-1">
                  <span className="tw-flex tw-shadow-sm tw-text-black tw-items-center tw-justify-center tw-font-bold tw-text-lg tw-rounded-full tw-w-10 tw-h-10 tw-bg-gradient-to-br tw-p-2 tw-from-gray-300/70 tw-via-gray-400/70 tw-to-gray-300/70 tw-backdrop-blur-sm">
                    {initials}
                  </span>
                </div>
                <span className="tw-text-black">{username}</span>
              </div>

              <ChevronDownIcon
                className={`tw-transition-all tw-duration-300 -tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-800 ui-open:tw-rotate-180`}
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-z-50 tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y-2 tw-divide-gray-200 focus:tw-outline-none">
              <div className="tw-py-1">
                {authCtx.accountType === "true" && admin}
                <Menu.Item>
                  {({ active }) => (
                    <>
                      <FavoritesSidebar />
                    </>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile"
                      className="me-2 tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-left tw-text-gray-700 tw-w-full tw-border-0 tw-outline-none hover:tw-text-sky-700 hover:tw-underline hover:tw-bg-gray-100"
                    >
                      <div className="tw-flex tw-flex-row">
                        <UserCircleIcon className="tw-h-5 tw-mr-1" />
                        Profile
                      </div>
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="tw-transition-all tw-duration-300 tw-ease-in-out tw-group tw-py-2 hover:tw-bg-red-200">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={
                        "tw-transition-all tw-text-red-500 tw-duration-300 tw-ease-in-out tw-block tw-px-4 tw-py-2 tw-text-sm tw-w-full"
                      }
                      onClick={logoutHandler}
                    >
                      <div className="tw-flex tw-flex-row">
                        <ArrowRightOnRectangleIcon className="tw-h-5 tw-w-5 tw-mr-1" />{" "}
                        Logout
                      </div>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
